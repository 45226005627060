<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <UserMenu></UserMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-3">
         <v-row class="rowBg">
          <v-col
            cols="11"
            md="2"
            offset-md="1"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">All Orders ({{orders.length}})</p>
          </v-col>

          <v-col
            cols="11"
            md="3"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #DED84C 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Awaiting Delivery ({{OrderAwaitingDelivery.length}})</p>
          </v-col>

          <v-col
            cols="11"
            md="3"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #618BBD 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Deleted Orders ({{OrdersDeleted.length}})</p>
          </v-col>

          <v-col
            cols="11"
            md="2"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #B55620 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Completed Orders ({{OrdersCompleted.length}})</p>
          </v-col>
        </v-row>

        
        <v-row class="rowBg mt-10">
          <v-col cols="12">
            <!-- Search using Tracking ID -->
            <v-row wrap>
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  outlined
                  label="Search using tracking Id...."
                  dense
                  append-icon="mdi-magnify"
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row wrap>
              <v-col cols="12">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="orders"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                >

                  <template v-slot:item.trackingNumber="{ item }">
                    <v-layout wrap>
                      <span>{{ item.trackingNumber }}</span>
                    </v-layout>
                  </template> 

                  <template v-slot:item.createdAt="{ item }">
                    <v-layout wrap>
                      <span >{{ item.createdAt | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</span>
                    </v-layout>
                  </template>


                  <template v-slot:item.status="{ item }">
                    <v-layout v-if="item.status == 'deleted'">
                      <span style="color:#BD2121">Order {{ item.status }}</span>
                    </v-layout>
                    <v-layout v-else-if="item.status == 'accepted'">
                      <span style="color:blue">Order {{ item.status }}</span>
                    </v-layout>
                    <v-layout v-else-if="item.status == 'created'">
                      <span style="color:green">Order {{ item.status }}</span>
                    </v-layout>
                    <v-layout v-else-if="item.status == 'awaiting-delivery'">
                      <span style="color:black">Order {{ item.status }}</span>
                    </v-layout>
                    <v-layout v-else-if="item.status == 'approved'">
                      <span style="color:#BD2121">Order Dispatched</span>
                    </v-layout>
                    <v-layout v-else>
                      <span style="color:black">Order {{ item.status }}</span>
                    </v-layout>
                  </template>


                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="ml-2"
                      v-on:click="editItem(item)"
                    >
                      mdi-dots-grid
                    </v-icon>
                  </template>
                </v-data-table>

                <v-dialog v-model="orderInfoDialog" width="600">
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Order Details
                      <v-spacer></v-spacer>
                      <v-btn icon @click="orderInfoDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <v-layout wrap>
                        <v-col cols="12">
                           <p><b>Order Id:</b> {{orderItem.orderId}}</p>
                           <p><b>Tracking Number:</b> {{orderItem.trackingNumber}}</p>
                           <p><b>Order Date:</b> {{orderItem.createdAt | moment("dddd, MMMM Do YYYY, h:mm:ss a")}}</p>
                        </v-col>
                      </v-layout>
                      <v-layout wrap>
                        <v-col cols="12">
                          <h5>Ordered Items</h5>
                          <v-divider></v-divider>
                           <v-row  v-for="(order, index) in orderItem.OrderItems"
                              :key="index" style="border-bottom:1px solid black; margin-bottom:10px;">
                            <v-col cols="3">
                              <v-img
                                class="ma-2"
                                width="70"
                                height="70"
                                :src="order.Product.productImagePath"
                                contain
                              />
                            </v-col>
                            <v-col cols="8">
                              <p><b>Product Name:</b> {{order.Product.name}}<br>
                              <b>Product Price:</b> {{systemSetting.systemCurrency}}{{order.Product.price}}<br>
                              <b>Quantity:</b> {{order.qty}}<br>
                              <b>Total Cost:</b> {{systemSetting.systemCurrency}}{{order.total}}<br>
                              </p>
                            </v-col>
                           </v-row>
                           <h5 style="border-bottom:1px solid black; padding-bottom:10px;">Total Paid: {{systemSetting.systemCurrency}}{{orderItem.subTotal}}</h5>
                        </v-col>
                      </v-layout>
                      <v-layout wrap>
                        <v-col cols="12">
                          <h5>Logitics Company</h5>
                          <v-divider></v-divider>
                           <v-row>
                            <v-col cols="12">
                              <p><b>Company name:</b> {{orderItem.LogisticsCompany.name}}</p>
                              <p><b>Contact Person:</b> {{orderItem.LogisticsCompany.contactPerson}}</p>
                              <p><b>Email:</b> {{orderItem.LogisticsCompany.email}}</p>
                            </v-col>
                           </v-row>

                        </v-col>
                      </v-layout>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-row v-if="orderItem.status == 'approved'">
                        <v-col cols="12" align="center" justify="center">
                          <v-layout>
                          <p>Please note that your order has been shipped. Please click on the "Order Received" Button as soon as you receive your package.</p>
                          </v-layout>
                          <v-layout>
                            <v-progress-linear
                              class="mt-3"
                              color="#7CBF46"
                              v-show="loader"
                              :indeterminate="true"
                            ></v-progress-linear>
                          </v-layout>
                          <v-layout>
                             <v-col cols="12" align="center" justify="center">
                              <v-btn
                                class="buttonStyle"
                                @click="approveOrderStatus(orderItem.orderId)"
                              >
                                Order Received
                              </v-btn>
                             </v-col>
                          </v-layout>
                        </v-col>
                      </v-row>
                      <v-row v-if="orderItem.status == 'created'">
                        <v-col cols="12" align="center" justify="center">
                          <v-layout>
                          <p>Please note that your order has been created and it's awaiting shipment.</p>
                          </v-layout>
                        </v-col>
                      </v-row>
                      <v-row v-if="orderItem.status == 'delivered'">
                        <v-col cols="12" align="center" justify="center">
                          <v-layout>
                          <p>Thank you for trusting us with your health. Please feel free to order your next prescription</p>
                          </v-layout>
                          <v-layout>
                             <v-col cols="12" align="center" justify="center">
                              <v-btn
                                class="buttonStyle"
                                to="/"
                              >
                                Shop
                              </v-btn>
                             </v-col>
                          </v-layout>
                        </v-col>
                      </v-row>
                       <v-row v-if="orderItem.status == 'completed'">
                        <v-col cols="12" align="center" justify="center">
                          <v-layout>
                          <p>Thank you for trusting us with your health. Please feel free to order your next prescription</p>
                          </v-layout>
                          <v-layout>
                             <v-col cols="12" align="center" justify="center">
                              <v-btn
                                class="buttonStyle"
                                to="/"
                              >
                                Shop
                              </v-btn>
                             </v-col>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserMenu from "../others/UserMenu.vue";

export default {
  name: "UserOrderList",

  components: {
    UserMenu,
  },

  data: () => ({
    loader:false,
    orderInfoDialog: false,
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "My Account",
        disabled: false,
        href: "/",
      },
      {
        text: "Order List",
        disabled: true,
        href: "/#/en/user/0/orders-list",
      },
    ],
    filters: false,
    menu: false,
    orders:[],
    search: "",
    selected: [],
    orderSearch: {
      orderNumber: "",
      product: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISO,
    },
    orderNumberRules: [
      (v) => !!v || "Order number is required",
      (v) => v.length <= 10 || "Number must be less than 10 characters",
    ],
    statusItems: [
      "All Orders",
      "Awaiting Payment",
      "Awaiting Cancellation",
      "Awaiting Delivery",
      "Order Complete",
      "Frozen Orders",
      "Payment not confirmed",
    ],
    OrderAwaitingDelivery:[],
    OrdersDeleted:[],
    OrdersCompleted:[],
    orderItem:{
      LogisticsCompany:{
        name:"",
        contactPerson:"",
        email:"",
      }
    },
    systemSetting: {
        systemCurrency: "₦",
    },
  }),
  computed: {
    headers() {
      return [
        { text: "Tracking Id", value: "trackingNumber" },
        { text: "Order Date", value: "createdAt" },
        { text: "Order Status", value: "status" },
        { text: "Action", value: "actions" },
      ];
    },
  },
  created() {
    this.fetchOrders();
  },
  methods: {
    toggleFilters() {
      this.filters = !this.filters;
    },
    fetchOrders() {
        this.$http
        .get(`${process.env.VUE_APP_URL}order/` + localStorage.getItem("userId"))
          .then((response) => {
            this.orders = response.data.data.reverse();
            response.data.data.forEach(element => {
              if(element.status == 'created'){
                this.OrderAwaitingDelivery.push(element);
              }

              if(element.status == 'deleted'){
                this.OrdersDeleted.push(element);
              }

              if(element.status == 'completed'){
                this.OrdersCompleted.push(element);
              }
            });
         
        });
    },
    editItem(item) {
      this.orderItem = item;
      this.orderInfoDialog = true;
    },
    approveOrderStatus(orderId){
        this.loader =true;
        
        this.$http
        .put(`${process.env.VUE_APP_URL}order/delivered/` + orderId)
        .then((response) => {
          console.log(response);
          this.loader =false;
          this.fetchOrders();
          this.orderInfoDialog = false;
        });
    },
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}

.orderStatBox {
  padding-top: 30px !important;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}

.orderStatText {
  color: white;
  font-size: 16px;
  text-align: center;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}
</style>
